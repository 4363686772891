import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertConfig } from 'src/app/models/alert';
@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
    @Input() config: AlertConfig;
    alertTypes = {
        alert: {
            title: 'Alert',
            titleClass: 'theme-red',
            iconClass: 'warning'
        },
        success: {
            title: 'Success',
            titleClass: 'theme-green',
            iconClass: 'greentick'
        }
    };
    constructor(public activeModal: NgbActiveModal) { }
}
