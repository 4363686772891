import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../core/services/utils/utils.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private router: Router,
        private ngZone: NgZone,
        private modalService: NgbModal,
        private utilsService: UtilsService
    ) { }

    /**
     * Check if the user is authenticated
     */
    isAuthenticated() {
        const authFlag = (this.utilsService.getPropertyValue('id_token') && this.utilsService.getPropertyValue('userInfo')) ? true : false;
        return authFlag;
    }

    /**
     * Returns the authentication token
     */
    getToken() {
        return this.utilsService.getPropertyValue('id_token');
    }

    /**
     * Logout the user from application
     * Clears the session values, closes all modals and navigates to login screen
     */
    logout(redirect: boolean = true) {
        this.utilsService.gigya.accounts.logout({
            callback: (response: any) => {
                this.ngZone.run(() => {
                    this.utilsService.clearSession();
                    this.modalService.dismissAll();
                    if (redirect) {
                        this.router.navigate(['/login']);
                    }
                });
            }
        });
    }

    /**
     * Check if the admin is authenticated
     */
    isAdminAuthenticated() {
        const authFlag = this.utilsService.getPropertyValue('id_token') ? true : false;
        return authFlag;
    }
}
