import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as english from '../../../../assets/i18n/en.json';

@Injectable({
    providedIn: 'root'
})
export class LanguageLoader implements TranslateLoader {
    constructor() {
    }

    getTranslation(lang: string): Observable<any> {
        let languageKeys: any;
        const en: any = (english as any).default;
        switch (lang) {
            case 'en':
                languageKeys = en;
                break;
            case 'de':
                languageKeys = en;
                break;
            default:
                languageKeys = en;
                break;
        }
        return of(languageKeys);
    }
}
