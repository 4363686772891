import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LanguageLoader } from './core/services/translate/translate-loader.service';
import { LoaderComponent } from './core/components/loader/loader.component';
import { AlertModule } from './shared/modules/alert.module';
import { LoggerService } from './core/services/logger/logger.service';
import { GlobalErrorhandlerService } from './core/services/interceptors/global-errorhandler.service';
import { HttpInterceptorService } from './core/services/interceptors/http-interceptor.service';

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: LanguageLoader
            }
        }),
        NgbModule,
        AlertModule
    ],
    providers: [
        Title,
        LoggerService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorhandlerService }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
