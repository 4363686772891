import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

    displayLoader = false; // boolean value to control the loader
    private subscription: Subscription; // Subscribe to the loader service subject

    constructor(private loaderService: LoaderService) { }

    ngOnInit() {
        setTimeout(() => {
            this.subscription = this.loaderService.loaderState
                .subscribe((state: { show: boolean }) => {
                    this.displayLoader = state.show;
                });
        }, 200);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
