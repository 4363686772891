import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private loaderSubject = new Subject();
    loaderState = this.loaderSubject.asObservable();

    constructor() { }

    /* Display loader */
    show() {
        this.loaderSubject.next({ show: true });
    }

    /* Hide loader */
    hide() {
        this.loaderSubject.next({ show: false });
    }
}
