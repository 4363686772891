import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../core/services/utils/utils.service';
import { AlertConfig } from '../models/alert';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    constructor(
        private translate: TranslateService,
        private utilsService: UtilsService,
    ) { }

    /**
     * Generates a reactive form based on form configuration
     * A formgroup is passed as parameter, so that we can add control to an existing formgroup
     * @params formConfig
     * @params formGroup
     */
    generateForm(formConfig: Array<any>, formGroup: FormGroup) {
        formConfig.forEach((formElement) => {
            const validators = formElement.validators ? formElement.validators.map((validator: any) => {
                return validator.validator;
            }) : [];
            formGroup.addControl(formElement.name, new FormControl(formElement.defaultValue, validators));
        });
        return formGroup;
    }

    /**
     * To display an error message to the user if there is an
     * error from the server end
     * @params errorCode
     */
    displayApiErrorResponse(errorCode: string) {
        errorCode = errorCode ? errorCode : '403042';
        const translateKey = 'shared.errors.error_' + errorCode;
        const translateKeys = [
            translateKey,
            'shared.errors.unknown_error',
            'screens.register.close'
        ];
        this.translate.get(translateKeys).subscribe((res: any) => {
            const alertConfig: AlertConfig = {
                type: 'alert',
                body: {
                    response: res[translateKey] ? res[translateKey] : res['shared.errors.unknown_error'],
                    query: '',
                    class: ''
                },
                cancelButton: null,
                submitButton: {
                    label: res['screens.register.close'],
                    class: 'submit-btn'
                }
            };
            if (this.utilsService && this.utilsService.openAlert) {
                this.utilsService.openAlert(alertConfig);
            }
        });
    }

    /**
     * To check for eauality of two form fields
     * @params targetFieldName
     * @params sourceFieldName
     * @params formGroup
     */
    equalityCheck(targetFieldName: string, sourceFieldName: string, formGroup: FormGroup) {
        const sourceVal: string = formGroup.get(sourceFieldName).value;
        const targetVal: string = formGroup.get(targetFieldName).value;
        if ((sourceVal && targetVal) && (sourceVal !== targetVal)) {
            formGroup.get(sourceFieldName).setErrors({ equality: true });
            formGroup.get(sourceFieldName).setValue(sourceVal);
        } else {
            formGroup.get(sourceFieldName).setErrors({ equality: null });
            formGroup.get(sourceFieldName).setValue(sourceVal);
        }
    }

    /**
     * One site can be in a site group with more sites.
     * All sites in the same site group share the same set of accounts.
     * The purpose of the First Login field is logging the sites in a site group into which the user already logged in.
     * For every site the hostname and the timestamps for the first and last login of the user are saved.
     * Updating the First Login field have to be implemented by all applications using Bayer Connected.
     * For more info visit: https://bayergroup.sharepoint.com/sites/BayerConnected/SitePages/First-Login-Field-(Mandatory).aspx
     * @params eventObj
     */
    firstLoginHandler(eventObj: any) {
        const application = location.hostname;
        const date = new Date();
        let apps = (eventObj && eventObj.data && eventObj.data.bc_accessApplications) ? eventObj.data.bc_accessApplications : undefined;
        // tslint:disable-next-line: variable-name
        let json_obj: any;
        const timestamp = date.toISOString();
        if (!apps) {
            // if application list empty, create new json object
            json_obj = { hostname: application, firstLogin: timestamp, lastLogin: timestamp };
            apps = [JSON.stringify(json_obj)];
        } else {
            let isNewApp = true;
            let appIndex: any;
            // is app new or already in the apps list?
            for (let i = 0; i < apps.length; i++) {
                // search for app in the array
                const host = JSON.parse(apps[i]).hostname;
                if (host === application) {
                    isNewApp = false;
                    appIndex = i;
                    break;
                }
            }
            if (isNewApp) {
                // app is new -> new entry in array
                json_obj = { hostname: application, firstLogin: timestamp, lastLogin: timestamp };
                apps.push(JSON.stringify(json_obj));
            } else {
                // app is already in array, actualize timestamp in lastLogin
                json_obj = JSON.parse(apps[appIndex]);
                json_obj.lastLogin = timestamp;
                apps[appIndex] = JSON.stringify(json_obj);
            }
        }
        // update apps list on server
        this.utilsService.gigya.accounts.setAccountInfo({ data: { bc_accessApplications: apps } });
    }

    /**
     * For accessibility compliance, we need to set focus to the main content on several occassions
     * This function will set the focus to the main div
     */
    focusToMainDiv() {
        const skipTag = document.getElementById('app-wrapper');
        if (skipTag) {
            skipTag.focus();
        }
    }
}
