import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './services/shared.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translate: TranslateService,
        private sharedService: SharedService
    ) {
        this.translate.setDefaultLang('en');
        this.translate.use('en');
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            if (evt instanceof NavigationEnd) {
                this.sharedService.focusToMainDiv();
            }
            window.scrollTo(0, 0);
        });
        /* Set dynamic title to application screens - START */
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data)
        ).subscribe((data) => {
            const title = data && data.title ?
                data.title : 'Healthcare Professional Samples';
            this.titleService.setTitle(title);
        });
        /* Set dynamic title to application screens - END */
    }

    /**
     * To enable 'Skip to main content' feature on accessib8ility perspective
     */
    scrollToMain() {
        const skipTag = document.getElementById('skip-to-content');
        skipTag.blur();
        const elmnt = document.getElementById('main');
        elmnt.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        elmnt.setAttribute('tabIndex', '-1');
        elmnt.focus({ preventScroll: true });
    }
}

