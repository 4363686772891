import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AlertConfig } from 'src/app/models/alert';

declare let gigya: any;

@Injectable({
    providedIn: 'root'
})

export class UtilsService {
    public date = moment;
    public gigya = gigya;

    constructor(private modalService: NgbModal) { }

    /**
     * Generic setter function
     * @param propertyName string
     * @param value value
     */
    setPropertyValue(propertyName: string, value: any) {
        localStorage.setItem(propertyName, value);
    }

    /**
     * Generic getter function
     * @param propertyName string
     */
    getPropertyValue(propertyName: string): any {
        return localStorage.getItem(propertyName);
    }

    /**
     * To clear a single item or clear the entire session
     * @param key : the key string on which the data is stored in session
     */
    clearSession(key?: string) {
        if (!key) {
            localStorage.clear();
        }
        localStorage.removeItem(key);
    }

    /**
     * Open an alert box (success, error or warning)
     * @params alertConfig
     */
    openAlert(alertConfig: AlertConfig) {
        const ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            backdropClass: 'blue-backdrop'
        };
        const modalRef = this.modalService.open(ModalComponent, ngbModalOptions);
        modalRef.componentInstance.config = alertConfig;
        return modalRef;
    }
}
