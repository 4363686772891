import { Injectable, isDevMode } from '@angular/core';
import { HttpService } from '../../../core/services/interceptors/http.service';
import { environment } from '../../../../environments/environment';

export enum LOGTYPES {
    ERROR = 'error',
    INFO = 'info'
}

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    constructor(private httpService: HttpService) { }

    /**
     * Checks if the environment is dev
     */
    isDevMode() {
        return isDevMode;
    }

    /**
     * logs error to console, if devMode
     * logs error to api, if production
     * @params message
     * @params stack
     */
    public logError(message: string, stack: string) {
        if (this.isDevMode()) {
            console.error(message, stack);
        } else {
            this.log(LOGTYPES.ERROR, JSON.stringify({ message, stack }));
        }

    }

    /**
     * Logs error to the API
     * @params type
     * @params log
     */
    public log(type: string, log: string): void {
        const logText = JSON.stringify({ type, log });
        /* Enable once server code is ready */
        // this.httpService.httpRequest(environment.endpoints.logger, 'POST', { logText }, true, {}, true).subscribe(
        //     (data) => {
        //         console.log(data);
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );
    }
}
