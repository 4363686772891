export const serviceEndpoints = {
    addressList: {
        endpoint: 'hcp-portal/user/addresses',
        isMock: false
    },
    productList: {
        endpoint: 'hcp-portal/products',
        isMock: false
    },
    placeOrder: {
        endpoint: 'hcp-portal/order',
        isMock: false
    },
    login: {
        endpoint: 'hcp-portal/user/login',
        isMock: false
    },
    validateNpi: {
        endpoint: 'hcp-portal/veeva/validate/npi',
        isMock: false
    },
    registerHcp: {
        endpoint: 'hcp-portal/public/user/register',
        isMock: false
    },
    orderList: {
        endpoint: 'hcp-portal/order',
        isMock: false
    },
    getProfile: {
        endpoint: 'hcp-portal/user',
        isMock: false
    },
    logger: {
        endpoint: 'logs'
    },
    closeAoc: {
        endpoint: 'hcp-portal/status/aoc/close',
        isMock: false
    },
    adminOrders: {
        endpoint: 'hcp-portal/admin/orders',
        isMock: false
    },
    orderStatusList: {
        endpoint: 'hcp-portal/admin/status/order',
        isMock: false
    },
    aocStatusList: {
        endpoint: 'hcp-portal/admin/status/aoc',
        isMock: false
    }
};
