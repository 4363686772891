import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../../../services/loader.service';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    requestHeaders: any;
    authorized: boolean;
    constructor(
        private http: HttpClient,
        private loaderService: LoaderService
    ) { }


    /**
     *  A global http service. Every http request should be passed through this
     * @param urlObj: object containing request url and mock flag
     * @param method: request method
     * @param data: request body
     * @param displayLoader: boolean
     * @param headers: custom headers
     */
    public httpRequest(
        urlObj: { endpoint: string, isMock?: boolean, pdf?: boolean },
        method: string,
        data = {},
        displayLoader: boolean = true,
        headers = {},
        authorized = true
    ): Observable<object> {
        /** Values to be handled within interceptor */
        this.requestHeaders = headers;
        this.authorized = authorized;

        /** Display loader based on flag */
        if (displayLoader) {
            this.loaderService.show();
        }

        /** Prepare full url based on mock / actual url */
        const baseUrl = urlObj.isMock ? environment.mockURL : environment.baseURL;
        const requetMethod = urlObj.isMock ? 'GET' : method;
        const url = baseUrl + urlObj.endpoint;

        switch (requetMethod) {
            case 'GET':
                return this.http.get(url);
            case 'POST':
                return this.http.post(url, data);
            case 'PUT':
                return this.http.put(url, data);
            case 'DELETE':
                return this.http.delete(url);
        }
    }
}
