import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from '../logger/logger.service';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorhandlerService implements ErrorHandler {

    constructor(private loggerService: LoggerService, private sharedService: SharedService) { }

    /**
     * Custom erro handler. All errors are redirected to here
     * @params error
     */
    handleError(error: any) {
        let message: string;
        let stackTrace: string;
        if (error instanceof HttpErrorResponse) {
            if (error.status !== 401) {
                this.sharedService.displayApiErrorResponse('5000');
            }
            // Server Error
            message = this.getServerMessage(error);
            stackTrace = this.getServerStack(error);
        } else {
            // Client Error
            message = this.getClientMessage(error);
            stackTrace = this.getClientStack(error);
        }
        this.loggerService.logError(message, stackTrace);
    }

    /**
     * Returns error message for client side errors
     * @params error
     */
    getClientMessage(error: Error): string {
        if (!navigator.onLine) {
            return 'No Internet Connection';
        }
        return error.message ? error.message : error.toString();
    }


    /**
     * Returns error stack for client side errors
     * @params error
     */
    getClientStack(error: Error): string {
        return error.stack;
    }


    /**
     * Returns error message for server response errors
     * @params error
     */
    getServerMessage(error: HttpErrorResponse): string {
        return error.message;
    }


    /**
     * Returns error status for server response errors
     * @params error
     */
    getServerStack(error: HttpErrorResponse): string {
        return error.statusText;
    }

}
