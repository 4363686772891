import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/services/guards/auth-guard.service';
import { LoggedInGuardService } from './core/services/guards/logged-in-guard.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: './screens/home/home.module#HomeModule',
        data: {
            title: 'Home'
        },
        canActivate: [LoggedInGuardService]
    },
    {
        path: 'login',
        loadChildren: './screens/login/login.module#LoginModule',
        data: {
            title: 'Login'
        },
        canActivate: [LoggedInGuardService]
    },
    {
        path: 'register',
        loadChildren: './screens/sign-up/sign-up.module#SignUpModule',
        data: {
            title: 'Registration'
        },
        canActivate: [LoggedInGuardService]
    },
    {
        path: 'activate',
        loadChildren: 'src/app/screens/activate-account-success/activate-account-success.module#ActivateAccountSuccessModule',
        data: {
            title: 'Activate Account'
        },
        canActivate: [LoggedInGuardService]
    },
    {
        path: 'resetPassword',
        loadChildren: 'src/app/screens/reset-password/reset-password.module#ResetPasswordModule',
        data: {
            title: 'Reset Password'
        },
        canActivate: [LoggedInGuardService]
    },
    {
        path: 'contact',
        loadChildren: './screens/contact-us/contact-us.module#ContactUsModule',
        data: {
            title: 'Contact Us'
        },
        canActivate: [LoggedInGuardService]
    },
    {
        path: 'layout',
        loadChildren: './layout/layout.module#LayoutModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'admin',
        loadChildren: 'src/app/screens/admin/admin-portal.module#AdminPortalModule'
    },
    {
        path: '**',
        redirectTo: '/login'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
